import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ViewComponent } from '../view/view.component';
import { AuthService } from 'src/app/services/auth.service';
import { ResolverService } from 'src/app/services/api/resolver.service';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { Helpers } from 'src/app/services/api/helpers';
import { BaseModel } from 'src/app/models/base.model';

@Component({
    selector: 'app-form-group',
    templateUrl: './form-group.component.html',
    styleUrls: ['./form-group.component.scss'],
})

export class FormGroupComponent<T> extends ViewComponent implements OnInit, AfterContentInit, OnDestroy {

    /** Name of subform in parent Form */
    @Input() name?: string;
    @Input() parent?: FormGroup;

    protected _valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Output() valid$Change: EventEmitter<BehaviorSubject<boolean>> = new EventEmitter<BehaviorSubject<boolean>>();
    @Input() set valid$(value: BehaviorSubject<boolean>) {
        this._valid$ = value;
        this.valid$Change.emit(this._valid$);
    }
    get valid$(): BehaviorSubject<boolean> {
        return this._valid$;
    }
    get valid(): boolean {
        return this._valid$.value;
    }

    protected _form: FormGroup;
    @Output() formChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Input() set form(value: FormGroup) {
        this._form = value;
        this.formChange.emit(this._form);
    };
    get form(): FormGroup {
        return this._form;
    }

    @Output() modelChange: EventEmitter<T> = new EventEmitter<T>();
    protected _model: T;
    @Input() set model(value: T) {
        // console.log('[PROJECT] MODEL SET', JSON.stringify(value));
        if (this.service) {
            this.service.current = value;
        } else {
            if (this._modelBuilder && !(value instanceof this._modelBuilder)) {
                this._model = new this._modelBuilder(value);
            } else {
                this._model = value;
            }
        }
        this.modelChange.emit(this.model);
    }
    get model(): T {
        if (this.service) {
            return this.service.current;
        }
        return this._model;
    }
    protected _modelBuilder?: new (data: any) => T = undefined;

    get controls(): { [x: string]: FormControl; } {
        return this.form?.controls as { [x: string]: FormControl; };
    }

    protected service: ResolverService<any> | undefined;



    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected s_alert: AlertService,
        protected s_loader: LoaderService,
        protected s_auth: AuthService,
        protected translate: TranslateService,
    ) {
        super(route, router, s_alert, s_loader, s_auth, translate);
    }

    async ngOnInit() {
        this.subscription.add(this.formChange.subscribe(
            (form) => {
                // console.log(`[FORM][${this.name || this.constructor.name}][FORM CHANGE]`);
                if (!_.isNil(form)) {
                    this.subscription.add(
                        this.form.valueChanges.subscribe((value) => {
                            if (this.service) {
                                this.precisePatch(this.service.current, value);
                                // console.trace(`[FORM][${this.name || this.constructor.name}][FORM VALUE CHANGES]`, this.form.getRawValue());
                                // this.service.current.patch(this.form.getRawValue());
                            }
                            this._valid$.next(this.form.valid);
                        })
                    );
                }
            }
        ));
        // Disable form on mode change
        this.subscription.add(this.mode$.subscribe(mode => {
            if (mode === this.VIEWMODE.VIEW) {
                this.form?.disable({
                    // emitEvent: false,
                });
            }
        }));
        this.initForm();
        this.link();
        this.subscription.add(this.modelChange.subscribe(this.patch.bind(this)));
        if (this.service) { this.subscription.add(this.service.current$.subscribe(this.patch.bind(this))); };
        await this.initSubscriptions();
        await this.initValidators();
    }

    //#region LIFECYCLE
    initForm() {
        console.log(`[FORM][${this.name || this.constructor.name}][INIT FORM]`);
    }

    async initValidators() {
        console.log(`[FORM][${this.name || this.constructor.name}][INIT VALIDATORS]`);
    }

    async initSubscriptions() {
        console.log(`[FORM][${this.name || this.constructor.name}][INIT SUBSCRIPTIONS]`);
    }

    getControl(path: string) {
        return this.form.get(path) as FormControl;
    }

    submitForm() {
        console.log(`[FORM][${this.name || this.constructor.name}][SUBMIT FORM]`);
    }

    resetForm(control: AbstractControl) {
        console.log(`[FORM][${this.name || this.constructor.name}][RESET FORM]`);
        this.controlAction(control, (control) => {
            if (control instanceof FormArray) {
                control.clear();
            } else if (control instanceof FormControl) {
                control.reset();
            }
        });
    };

    resetFormArray(control: AbstractControl) {
        this.controlAction(control, (control) => {
            if (control instanceof FormArray) {
                const group = control as FormArray;
                while (group.length) {
                    group.removeAt(0);
                }
            }
        });
    }

    protected link() {
        if (this.parent && this.name) {
            console.log(`[FORM][${this.name}][LINKING FORM]`);
            this.parent.setControl(this.name, this.form, /*{ emitEvent: false }*/);
        }
    }

    protected unlink() {
        if (this.parent && this.name) {
            // console.log(`[FORM][${this.name}][UNLINKING FORM]`);
            this.parent.removeControl(this.name, /*{ emitEvent: false }*/);
        }
    }


    //#endregion
    isFormValid(emit: boolean = true, _form?: FormGroup): boolean {
        const form = _form || this.form;
        if (_.isNil(form)) {
            return false;
        }
        this.triggerValidation(form, emit);
        this.valid$.next(this.form.valid);
        return form.valid;
    }

    protected triggerValidation(control: AbstractControl, emit: boolean) {
        this.controlAction(control, (control) => {
            control.updateValueAndValidity({ onlySelf: true, emitEvent: emit });
        });
    }

    protected controlAction(
        control: AbstractControl,
        action: (control: AbstractControl) => void
    ) {
        if (control instanceof FormGroup) {
            const group = control as FormGroup;

            for (const field in group.controls) {
                const c = group.controls[field];

                this.controlAction(c, action);
            }
        } else if (control instanceof FormArray) {
            const group = control as FormArray;

            for (const field in group.controls) {
                const c = group.controls[field];

                this.controlAction(c, action);
            }
        }
        action(control);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.unlink();
    }

    patch(model: { [key: string]: any; }, select?: string[]) {
        // console.log('[FORM][PATCHING][PROJECT]', JSON.stringify(model));
        if (model) {
            // this.model = model as any;
            // if (this._modelBuilder) {
            //     this._model = new this._modelBuilder(model);
            // }
            if (this?.controls) {
                let keys = Object.keys(this.controls);
                if (select && _.isArray(select)) {
                    keys = select;
                }
                // console.log(`[FORM][${this.name || this.constructor.name}][PATCHING]`, model, keys)
                for (let key of keys) {
                    // console.log('[FORM][IS VALID]', key, this.controls[key].valid,  JSON.stringify(model[key]), JSON.stringify(this.controls[key].value));

                    if (model[key] !== undefined && !_.isEqual(this.controls[key].value, model[key])) {
                        //console.log('[FORM][IS VALID B4]', this.controls[key].valid, key, JSON.stringify(model[key]), JSON.stringify(this.controls[key].value));
                        this.controls[key].patchValue(model[key], {
                            // emitEvent: false,
                        });
                        if (!this.controls[key].valid) {
                            console.error('[FORM][ERRORS]', key, this.controls[key].value, this.getFormErrors(this.form));
                        }
                    }
                }
                this.valid$.next(this.isFormValid(false));
            }
        } else {
            console.warn(`model is Nil, patching entity ${typeof model} canceled`);
        }
        // this.isFormValid();
    }

    getFormErrors(form: FormGroup): { [key: string]: string[]; } {
        const errors: { [key: string]: string[]; } = {};
        Object.keys(form.controls).forEach((key: string) => {
            const control = form.get(key);
            if (control instanceof FormGroup) {
                const nestedErrors = this.getFormErrors(control);
                Object.keys(nestedErrors).forEach((nestedKey: string) => {
                    errors[`${key}.${nestedKey}`] = nestedErrors[nestedKey];
                });
            } else if (control?.errors) {
                errors[key] = Object.keys(control.errors);
            }
        });
        return errors;
    }


    /**
     * Waits for a value change in a specified form control.
     *
     * This method listens for changes in the root form's value, maps it to a specific form control,
     * and then waits for a distinct value change in that control. It completes after the first change.
     *
     * @param controlMap - A function that returns the form control to be monitored for value changes.
     * @returns An observable that emits the value changes of the specified form control.
     */
    protected waitValueChange$(controlMap: (root: AbstractControl) => AbstractControl | null) {
        return (this.form.root.valueChanges.pipe(
            map(() => controlMap(this.form.root)),
            filter((control): control is AbstractControl => !!control),
            take(1),
            switchMap(control => control.valueChanges.pipe(distinctUntilChanged()))
        ));
    }



    private cached_form_value: any = undefined;
    /**
     * Applies precise updates to the current object based on the differences between the updated object and the cached form value.
     * 
     * @param current - The current object to be patched.
     * @param updated - The updated object containing new values.
     * 
     * This method calculates the differences between the `updated` object and the `cached_form_value` using `Helpers.diffObjects`.
     * It then updates the `cached_form_value` to the `updated` object.
     * For each key in the differences, it sets the corresponding value in the `current` object using `_.set`.
     * Additionally, it logs the changes to the console with the format `[FORM][<component name>][FORM VALUE CHANGES]`.
     */
    private precisePatch(current: BaseModel<any>, updated: any) {
        if (this.cached_form_value) {
            const diff = Helpers.diffObjects(updated, this.cached_form_value);
            this.cached_form_value = updated;
            for (let key in diff) {
                _.set(current, key, diff[key]);
                // console.log(`[FORM][${this.name || this.constructor.name}][FORM VALUE CHANGES]`, key, diff[key]);
            }
        } else {
            this.cached_form_value = updated;
            current.patch(updated);
        }
    }
}